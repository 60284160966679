<template>
  <div class="register">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="annual">
      <div class="w1300">

        <div class="sub_header_description txt-white page_header_space">
          <h3 class="sub_header_description_title">台灣居家醫療醫學會2020年學術研討會暨第二屆年會</h3>
          <p>今年主題著重於居家安寧病人照護實務經驗。</p>
          <p>藉由實務經驗分享，互相學習交流，讓居家醫療更能符合患者需求。</p>
        </div>

        <img class="heart_bg" src="@/statics/img/about/bg_heart.png" alt="">

      </div>
    </section>

    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section id="annual_member">
      <div class="w1300 annual_container">

        <div class="main_title_box">
          <h3 class="main_title txt-bold">非會員報名</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="annual_container_box">

          <section id="form_detail">
            <div class="payment_member">
              <h4 class="payment_member_name txt-bold mg-tb-10">王小明</h4>
              <p class="payment_member_profession">(身分：<span id="identity">護理人員</span>)</p>
            </div>

            <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="td-main txt-left"><p>費用名稱</p></th>
                  <th class="min100"><p>年度</p></th>
                  <th class="min100"><p>金額</p></th>
                  <th class="min130"><p>備註</p></th>
                </tr>
              </thead>
              <tbody id="table_data">

                <!-- 護理人員身分顯示 -->
                <tr>
                  <td data-title="費用名稱" class="td-main txt-left"><p>學術研討會報名費</p></td>
                  <td data-title="年度"><p>2020</p></td>
                  <td data-title="金額"><p>1600</p></td>
                  <td data-title="備註"></td>
                </tr>

                <!-- 醫師身分顯示 -->
                <tr>
                  <td data-title="費用名稱" class="td-main txt-left"><p>學術研討會報名費</p></td>
                  <td data-title="年度"><p>2020</p></td>
                  <td data-title="金額"><p>2000</p></td>
                  <td data-title="備註"></td>
                </tr>
              </tbody>
            </table>
            </div>

            <div class="payment_check txt-right">
              <p class="payment_check_total mg-tb-10">
                總金額 <span class="h3 txt-light_green">1600</span> 元
              </p>
                <form
                  action="register_annual_memberFalse_success.php"
                  method="post"
                  id="form_payment"
                  name="form_payment"
                >
                  <div class="payment_check_container mg-b-10">
                    <select name="pay_type" id="pay_type" required>
                      <option value="" disabled selected>請選擇繳費方式</option>
                      <option value="線上刷卡">信用卡</option>
                      <option value="ATM">ATM轉帳</option>
                      <option value="CVS">超商代碼繳費</option>
                    </select>
                  </div>

                  <!-- 醫師生身分顯示下方連結 -->
                  <a href="application_apply.php" class="click_to_apply h4 txt-bold mg-b-50">
                    本會會員免報名費，點擊前往申請
                  </a>

                  <div class="form_button">
                    <router-link to="/register/annual/nonmember" class="btn btn-gray">
                      修改報名表
                    </router-link>
                    <router-link to="/register/annual/success" class="btn p">
                      前往繳費
                    </router-link>
                  </div>
                </form>
            </div>
          </section>

        </div>

      </div>
    </section>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterAnnualSubHeader } from '@/lib/const';

export default {
  name: 'RegisterAnnualPayment',
  data() {
    return {
      ...RegisterAnnualSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
